import { render, staticRenderFns } from "./Checkout.vue?vue&type=template&id=32d9fd68&"
import script from "./Checkout.vue?vue&type=script&lang=js&"
export * from "./Checkout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/danyelsanches/Documents/projetos/educatena/educatena-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('32d9fd68')) {
      api.createRecord('32d9fd68', component.options)
    } else {
      api.reload('32d9fd68', component.options)
    }
    module.hot.accept("./Checkout.vue?vue&type=template&id=32d9fd68&", function () {
      api.rerender('32d9fd68', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/marketing_place/store/Checkout.vue"
export default component.exports